// Common
import './lib/common';

// Load Web components
import '@firebase-utils/firebase-loginbutton/firebase-loginbutton.js';
import '@firebase-utils/firebase-crud/firebase-crud.js';

let fbCRUD;
let firebaseSigninReady = false;
let fbCRUDReady = false;

let user;
let displayName;
let email;
let photoURL;
let uid;

async function initApp() {
  const newText = await fbCRUD.getData('/text/index');
  const normas = document.getElementById('normas');
  const li = document.createElement('li');
  li.innerText = newText;
  li.classList.add('siguiente-pista');
  normas.appendChild(li);
}


/*************************/

async function loginReady() {
  // console.log('loginReady');
  if (fbCRUDReady && firebaseSigninReady) {
    initApp();
  }
}

document.addEventListener('wc-ready', (ev) => {
  if (ev.detail.id === 'login-button' && !firebaseSigninReady) {
    fbCRUD = document.createElement('firebase-crud');
    fbCRUD.setAttribute('id', 'firebasecrud');
    fbCRUD.setAttribute('reference-id', 'login-button');
    document.body.appendChild(fbCRUD);
  }
  if (ev.detail.id === 'firebasecrud' && !fbCRUDReady) {
    fbCRUDReady = true;
    setTimeout(() => { loginReady() }, 100);
  }
});

document.addEventListener('firebase-signin', (ev) => {
  if (firebaseSigninReady) {
    return;
  }
  // console.log('firebase-signin');
  firebaseSigninReady = true;
  user = ev.detail.user;
  loginReady();
});

document.addEventListener('firebase-signout', (ev) => {
  if (!firebaseSigninReady) {
    return;
  }
  // console.log('firebase-signout');
  firebaseSigninReady = false;
  fbCRUDReady = false;
  document.querySelector('.siguiente-pista').remove();
});